import readyHandler from "../lib/readyHandler";

readyHandler.ready(() => {
  const facebookAppId = document.body.dataset.facebookid;

  if (facebookAppId) {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: facebookAppId,
        status: true, // check login status
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse XFBML
        version: "v20.0"
      });

      // Adjust canvas size
      //FB.Canvas.setAutoResize();
    };

    (function (d) {
      const id = "facebook-jssdk";
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement("script");
      js.id = id;
      js.async = true;
      js.src = "//connect.facebook.net/en_US/all.js";
      d.getElementsByTagName("head")[0].appendChild(js);
    })(document);
  }
});
